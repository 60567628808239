.services{
    height: auto;
    min-height: 300px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border: 1px solid white;
    text-align: left;
    margin-top: 5rem;
    color: var(--color-primary);
    position: relative;
}

.services h2{
    font-size: 3rem;
}

.services h3{
    font-size: 1.8rem;
    margin-bottom: 2.8rem;
}

.services p:first-child{
    font-size: 1.5rem;
}

.service p:last-child{
    /* display: none; */
}

.service{
    padding: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: transparent;
    perspective: 1000px;
    min-height: 300px;
    height: auto;
}

.service:first-child p{
    font-size: 1.3rem;
    line-height: 1.2;
}

.service__inner{
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.service__back{
    color: white;
    transform: rotateY(180deg);
}

.service:hover .service__inner{
    transform: rotateY(180deg);
}

.service__front, .service__back{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

.service:first-child{
    cursor: default;
}
.service:nth-child(2n+0){
    background-color: var(--color-complementary);
}

.service__icon{
    font-size: 3rem;
}

.service__back-icons{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}
.service__back-icons{
    margin-bottom: 2rem;
}
.service__back-content h4 {
    margin-bottom: 15px;
}
.service__back-icon{
    font-size: 2.5rem;
}

.service__back-content-item{
    display: flex;
    gap: 10px;
    font-size: 1.25rem;
    justify-content: start;
    align-items: center;
    margin-bottom: 15px;
}

/* ########################### CTA  #####################*/
.Cta{
    
    justify-content: start;
    justify-items: start;
    margin: 5rem auto;
}

.Cta div{
    display: grid;
    max-width: 620px;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    width: auto;
    align-items: center;
}

.contact_btn{
    width: fit-content !important;
    height: fit-content;
}

.Cta div p {
    color: var(--color-primary);
    font-size: 2rem;
    line-height: 1.1;
}

@media screen and (max-width: 769px){
    .Cta div{
        grid-template-columns: 1fr;
        gap: 30px;
    }
}

/* ######################## MEDIUM DEVICES ###################### */
@media screen and (min-width: 768px) and (max-width: 1024px){
    .services{
        grid-template-columns: 1fr 1fr;
        margin-top: 10rem;
    }
    .service{
        min-height: 420px;
    }
    .service:nth-child(3){
        background-color: var(--color-complementary);
    }
    .service:nth-child(4){
        background-color: transparent;
    }
}




/* ######################## SMALL DEVICES ######################### */
@media screen and (max-width: 767px){
    .services{
        grid-template-columns: 1fr;
    }

    .service{
        padding: 30px 15px 30px;
        min-height: 350px;
    }
    .service__back-icons{
        margin-bottom: 3rem;
    }
    .service__back-content h4, .service__back-content .service__back-content-item{
        margin-bottom: 15px;
    }
}