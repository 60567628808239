#contact{
    margin-top: 10rem;
}

.contact__heading{
    text-align: left;
    color: var(--color-primary);
    display: flex;
    margin-bottom: 10rem;
    gap: 10%;
}

.contact__heading h3{
    font-size: 6rem;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
}

.contact__heading h4{
    font-size: 2rem;
    margin-bottom: 3rem;
    margin-top: 0;
}

.contact__interests{
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.contact__interests p{
    border: 1px solid var(--color-primary);
    padding: 10px 20px;
    margin: 0;
    font-size: 1.5rem;
    position: relative;
}

.contact__interests p:hover{
    transition: all 1s ease-out;
    filter: drop-shadow(2px 4px 6px #ffde59);
}

.contact__interests p .vertical{
    position: absolute;
    top: -5px;
    left: -1px;
    border: 1px solid rgb(204, 255, 0);
    height: calc(100% + 10px);
    width: 100%;
    transform: scale(1);
    transition: all 1s ease-in;
}

.contact__interests p:hover .vertical{
    transform: scale(0);
    transition: all 1s ease-out;
}

.contact__interests p .horizontal{
    position: absolute;
    top: -1px;
    left: -5px;
    border: 1px solid rgb(204, 255, 0);
    height: 100%;
    width: calc(100% + 10px);
    transform: scale(1);
    transition: all 1s ease-in;
}

.contact__interests p:hover .horizontal{
    transform: scale(0);
    transition: all 1s ease-out;
}


.contact__cta{
    display: flex;
    margin-top: 5rem;
    gap: 30px;
    align-items: center;
}

.contact__cta p{
    font-size: 1.5rem;
    font-weight: 500;
}

.contact__cta h4{
    font-size: 2rem;
    margin: 12px 0;
    text-decoration:underline;
}

.container.contact__container{
    width: 100%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
    color: var(--color-primary);
    margin-bottom: 10rem;
}



.contact__options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option{
    background: var(--color-complementary);
    padding: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact__option h4{
    margin: 0;
}
.contact__option div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.contact__option div:first-child svg{
    margin: auto 0;
}

.contact__option > h5{
    overflow-wrap: break-word;
    font-size: 1rem;
    margin-top: 0.5rem;
}

.contact__option:hover{
    background: transparent;
    border-color: var(--color-complementary);
}

.contact__option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1rem;
    color: var(--color-primary);
}

/* ============= FORM ================== */
form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: #fff;
    font-size: 1rem;
}

input::placeholder, textarea::placeholder{
    color: #eee;
}

input:focus-visible, textarea:focus-visible{
    outline: none;
}

button{
    margin: 0 auto;
}
form .btn{
    margin-top: 10px;
}
.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-buttons);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    font-size: 1.25rem;
  }
  
  .btn:hover{
    background: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
  }
  
  .btn-primary{
    background: var(--color-primary);
    color: var(--color-complementary);
  }



/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1024px){
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .contact__heading{
        flex-direction: column;
        margin-bottom: 3rem;
    }
}


/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 768px){
    .container.contact__container{
        grid-template-columns: 1fr;
        width: 100%;
    }
    #contact{
        margin-top: 3rem;
    }
    .contact__heading{
        flex-direction: column;
        margin-bottom: 3rem;
    }
    .contact__heading h3{
        font-size: 4rem;
        margin-bottom: 4rem;
    }
    .contact__cta{
        flex-direction: column;
    }
    
}