.container.testimonials__container{
    width: 40%;
    padding-bottom: 3rem;
}
.client__avatar{
    width: 5rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary);
}

.testimonial{
    background: var(--color-complementary);
    border: 2px solid var(--color-primary);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    transition: var(--transition);
}

.client__review{
    color: #fff;
    font-weight: 500;
    display: block;
    width: 80%;
    margin: 0.3rem auto 2rem;
    font-size: 1.2rem;
    line-height: 1.1;
}
.client__name{
    font-size: 1.5rem;
}
.client__company{
    color: var(--color-primary);
    line-height: 1.1;
    font-size: 1.3rem;
    margin: 20px auto;
}
.client__avatar picture img{
    width: 100%;
    height: auto;
    padding: 0px;
}

.swiper-pagination-bullet{
    background-color: var(--color-primary);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1024px){
    .container.testimonials__container{
        width: 60%;
    }
}


/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 768px){
    .container.testimonials__container{
        width: var(--container-width-sm);
        overflow: hidden;
    }
    .client__review{
        width: var(--container-width-sm);
    }
}