code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
    border: 0;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
}

:root{
    /* COLORS */
    /* GOLD: FFDE59 */
    /* blue: 00c2cb */
    --color-primary: #FFDE59;
    --color-contrast: #000000;
    --color-complementary: #2596be;
    --color-buttons: #1a7485;
    --bg-color: #1a7485;
    --color-contact-btn: #FFDE59;


    /* SIZES */
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    /* EFFECTS */
    --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
  }
  
  ::-webkit-scrollbar{
    display: none;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-color);
    line-height: 1.7;
  }


  /* GENERAL STYLES */
  .container{
    width: var(--container-width-lg);
    margin: 0 auto;
    max-width: 1240px;
  }

  h1, h2, h3, h4, h5, h6{
    font-weight: 500;
  }

  h1{
    font-size: 2.5rem;
  }
  
  section{
    margin-top: 8rem;
  }

  .contact_btn{
    padding: 15px 30px;
    border: 1px solid var(--color-primary);
    font-size: 2rem;
    --_g1: conic-gradient(from 90deg at 50% 75%,rgb(215, 41, 41)  90deg,#fff 0) no-repeat;
    --_g2: conic-gradient(from 90deg at 50% 75%,rgba(28, 208, 25, 0) 90deg,rgb(210, 223, 25) 0) no-repeat;
    background: 
      var(--_g1) 0    calc(134% - var(--_p,0%)),
      var(--_g2) 0    calc(134% - var(--_p,0%)),
      var(--_g1) 100% calc(200% - var(--_p,0%)),
      var(--_g2) 100% calc(200% - var(--_p,0%)),
      #000;
    background-size: 50.2% 400%;
    -webkit-background-clip: text, padding-box;
            background-clip: text, padding-box;
    transition: 0.8s;
    color: var(--color-primary)
}

  /* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1200px){
    .container{
      width: var(--container-width-md);
    }
  
    section{
      margin-top: 6rem;
    }
  }
  
  
  /* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
  @media screen and (max-width: 768px){
    .container{
      width: var(--container-width-sm);
    }
  }