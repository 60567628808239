/* ################### HEADER SOCIALS ##################### */

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 15rem;
}

.header__socials svg{
    fill: var(--color-primary);
    width: 1.5em;
    height: 1.5em;
    transition: all 400ms ease-in-out;
}

.header__socials svg:hover{
    filter: drop-shadow(2px 4px 6px black);
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


/* ======================== SCROLL DOWN ======================= */
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 15rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1.2rem;
    color: var(--color-primary);
}


/* ############### HEADER ########################### */
.header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    max-height: 1000px;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

h1, h2, h5{
    color: var(--color-primary);
    margin: 0;
}

.profile__container{
    width: 85%;
    height: 100%;
    margin: auto;
    background-image: url("../../assets/profile-bg.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0%;
}

.profile{
    /* background: linear-gradient(var(--color-primary) 25%, transparent 75%); */
    background: var(--color-primary);
    width: 30rem;
    height: 30rem;
    margin: 4rem auto;
    border-radius: 50%;
}

.profile img{
    width: 75%;
    margin-top: 80px;
    filter: drop-shadow(2px 4px 6px black);
    animation-name: profile_drop_shadow;
    animation-duration: 4s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
}

@keyframes profile_drop_shadow{
    0%{
        filter: drop-shadow(2px 4px 6px black);
    }

    25%{
        filter: drop-shadow(2px 4px 6px rgb(51, 0, 128));
    }

    50%{
        filter: drop-shadow(2px 4px 6px rgb(0, 192, 239));
    }

    100%{
        filter: drop-shadow(2px 4px 6px rgb(16, 239, 0));
    }
}

.circle{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: absolute;
    background-color: var(--color-primary);
}

.circle_one{
    left: 30%;
}

.circle_two{
    left: 20%; 
    top: 30%;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1024px){
    .header{
        height: 68vh;
        min-height: 700px;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px){
    .header{
        height: 90vh;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 768px){
    .header{
        height: 100vh;
        padding-top: 3rem;
    }

    .profile{
        width: 80%;
        height: calc(100vw - 15%);
    }

    .profile img{
        position: absolute;
        left: 20px;
    }

    .profile__container{
        width: 100%;
        background-size: 45rem;
    }

    .header .scroll__down{
        display: none;
    }

    .header .header__socials{
       bottom: 6rem;
    }

    .circle_one{
        left: 0;
    }

    .circle_two{
        right: 5%;
        bottom: 30%;
        top: unset;
        left: unset;
    }
}

