.portfolio{
    height: auto;
    margin-top: 5rem;
    position: relative;
    overflow: hidden;
}

.portfolio__title{
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 5rem;
}
.portfolio__title h2{
    font-size: 4rem;
    width: fit-content;
    text-align: center;
    z-index: 1;
}

.portfolio__form{
    width: 160px;
    height: 150px;
    background-color: #2596be;
    border-radius: 50% 30% 30% 50%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    animation-name: title-animate;
    animation-duration: 4s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
}

@keyframes title-animate{
    0%{
        width: 160px;
        height: 150px;
        background-color: #2596be;
        border-radius: 50% 30% 30% 50%;
        left: 50%;
    }

    25%{
        width: 160px;
        height: 150px;
        background-color: #aabe25;
        border-radius: 80% 30% 20% 10%;
        left: 80%;
    }

    50%{
        width: 160px;
        height: 150px;
        background-color: #a425be;
        border-radius: 80% 30% 20% 70%;
        left: 0;
    }

    100%{
        width: 160px;
        height: 150px;
        background-color: #2596be;
        border-radius: 50% 30% 30% 50%;
        left: 50%;
    }
}

.portfolio__content{
    display: flex;
    flex-direction: row;
    gap: 5%;
    text-align: left;
    color: var(--color-primary);
    align-items: center;
}
.portfolio__content .text{
    font-size: 5rem;
    max-width: 35%;
    line-height: 1;
    font-weight: bold;
    justify-content: center;
}
.portfolio__content .text p {
    max-width: 50%;
}
.portfolio__content .projects{
    width: 60%;
    display: grid;
    position: relative;
}

.portfolio__content .projects .project{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 50%;
    border-bottom: 1px solid var(--color-primary);
    padding: 10px 5px;
    font-size: 1.25rem;
    align-items: center;
    cursor: pointer;
    transition: all 4s;
}

.portfolio__content .projects .project a{
    color: var(--color-primary);
    font-size: 1.25rem;
    padding: 10px 5px;
    text-decoration: none;
}

.portfolio .project__details img{
    max-width: 250px;
    width: 100%;
    height: auto;
    max-height: 500px;
    position: absolute;
    top: 0;
    transform: perspective(1000px) rotateY(-10deg) rotatex(10deg);
    right: 10px;
    z-index: 2;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.7);
}

.portfolio .project:hover{
    background-color: #00000054;
    transition: cubic-bezier() 200ms ease-in;
}
.portfolio .project:hover .project__details img{
    animation-name: rotate;
    animation-duration: 10s;
    animation-delay: 1s;
}

@keyframes rotate{
    from {
        transform: perspective(1000px) rotateY(-10deg) rotatex(10deg);
    }

    to{
        transform: perspective(1000px) rotateY(350deg) rotatex(10deg);
    }
}

.project__technologies .project__technology{
    font-size: 3rem;
}

.project__technologies{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: fit-content;
}

/* ########################## MEDIUM DEVICES ####################### */
@media screen and (max-width: 1024px){
    .portfolio__content{
        flex-direction: column;
    }
    .portfolio__content .text{
        max-width: 100%;
    }
    .portfolio__content .text p{
        max-width: 50%;
        font-size: 6rem;
        width: 30%;
    }
    .portfolio__content .projects{
        max-width: 100%;
        width: 100%;
    }

    .portfolio__content .projects .project{
        font-size: 0.8rem;
    }
}


/* #############################3 SMALL DEVICE ########################## */
@media screen and (max-width: 767px){
    .portfolio__content{
        flex-direction: column;
        /* overflow: hidden; */
    }
    .portfolio{
        overflow: hidden;
        padding-bottom: 200px;
    }

    .portfolio__content .text{
        max-width:100%;
        margin-bottom: 5rem;
    }
    .portfolio__content .projects{
        max-width: 100%;
        width: 100%;
    }
    .portfolio__content .projects .project{
        max-width: 100%;
        grid-template-columns: 1fr !important;
        position: relative;
    }

    .portfolio .project__details img{
        max-width: 200px;
        transform: perspective(500px) rotateY(-20deg) rotatex(0deg);
        right: 0;
    }
}