.about{
    height: auto;
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 50% 40% ;
    gap: 10%;
    grid-auto-rows: 1fr;
    max-height: 1000px;
}

.about .left {
    color: var(--color-primary);
    text-align: left;
}

.about .left h5{
    font-size: 2rem;
    line-height: 2rem;
    padding: 0;
    margin-top: 0;
}

.about .left h2{
    font-size: 5rem;
}

.about .left p{
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 4rem;
}

.about .right{
    overflow: hidden;
}
.about .right img{
    max-width: 100%;
    transform:  skew(-15deg, 0deg);
    overflow: hidden;
}

.about .right:hover img{
    /*  */
}

@keyframes about_animation {
    from{
        transform:  skew(-180deg, 0deg) scale(0.5);
    }

    to{
        transform:  skew(-15deg, 0deg) scale(1);
    }

}



.contact_btn:hover{
    --_p: 134%;
    color: var(--color-complementary);
}


/* ############################## MEDIUM DEVICES ########################## */





/* ############################## SMALL DEVICES ########################## */

@media screen and (max-width: 767px){
    .about{
        height: 100%;
        grid-template-columns: 1fr;
        gap: 3%;
        grid-auto-rows: auto;
        max-height: none;
    }
    .about .left h2{
        font-size: 4rem;
        line-height: 4.5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .about .left p{
        font-size: 1.5rem;
    }
    .about .right img{
        margin-bottom: 5rem;
    }

}